<template>
  <div class="modal fade" id="categoryDeleteModal" tabindex="-1" aria-labelledby="categoryDeleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="categoryDeleteModalLabel">Eliminar categoría</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <div class="mb-3" align="center">
              <i class="bi bi-exclamation-circle text-danger display-1 d-block"></i>
              ¿Estás seguro que quieres eliminar la categoría {{ form.Valor }}?
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-danger" @click="deleteItem">
              Eliminar
            </button>
          </div>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APICategory from '@/api/category';
import { reactive } from 'vue';
import $ from 'jquery'

export default {
  props: {
    form: Object,
  },

  emits: ['deleted'],

  setup(props, { emit }) {
    const alert = reactive({
      message: "",
      title: "",
    })

    function deleteItem() {
      APICategory.delete(props.form.ID)
        .then(() => {
          $('#categoryDeleteModal').modal('hide');
          emit('deleted');
        })
        .catch((error) => {
          alert.title = "Error al eliminar";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    }

    return {
      alert,
      deleteItem,
    }
  }
}
</script>
