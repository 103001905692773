<template>
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-block">
      <h4 class="text-dark">Archivos Programas</h4>
    </div>

    <div class="col-auto ml-auto text-right mt-n1">
      <button type="button" class="btn btn-info mr-3" @click="loadList">
        <i class="bi bi-arrow-clockwise"></i> Actualizar
      </button>

      <button type="button" class="btn btn-primary" @click="showCreate">
        <i class="bi bi-cloud-arrow-up"></i> Subir archivo
      </button>
    </div>
  </div>

  <table id="fileProgramTable" class="table table-sm table-striped bg-white" style="width:100%">
    <thead class="thead-dark">
      <tr>
        <th class="font-weight-normal">Origen</th>
        <th class="font-weight-normal">Descripción</th>
        <th class="font-weight-normal">Estatus</th>
        <th class="actions"></th>
      </tr>
    </thead>
  </table>

  <modal-form
    @store="loadList"
  ></modal-form>

  <error-details
    :form="itemSelected"
  ></error-details>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import ErrorDetails from '@/components/FileProgram/ErrorDetails';
import ModalForm from '@/components/FileProgram/ModalForm';
import { onMounted, reactive, ref, nextTick } from 'vue';
import CONSTANS from '@/util/constants';
import actions from '@/util/actions';
import $ from 'jquery';

export default {
  components: {
    'modal-form': ModalForm,
    'error-details': ErrorDetails,
  },

  setup() {
    const alert = reactive({
      message: "",
      title: "",
    })
    const fileStatus = ref({
      1: 'Cargando',
      2: 'En proceso',
      3: 'Procesado con éxito',
      4: 'Procesado con error',
      5: 'Error, no se pudo abrir el archivo',
    })

    const itemSelected = ref({})

    const fileProgramTable = ref(null)

    onMounted(() => {
      initTable();
    })

    function initTable() {
      nextTick(() => {
        fileProgramTable.value = $('#fileProgramTable').DataTable({
          ajax:  {
            url: `${CONSTANS.API_URL}/obtenerarchivo`,
            dataSrc: 'result',
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", `Bearer ${localStorage.token}`);
            },
            error: function(request) {
              if (request.status == 401) {
                actions.logout();
              }

              alert.title = "Error al buscar";
              alert.message = request.response ? request.response.data.msg : 'Error en la petición';
              $('#toast').toast('show');
            },
          },
          columns: [
            { className: 'font-weight-light', data: "Origen" },
            { className: 'font-weight-light', data: "Descripcion" },
            { className: 'font-weight-light', data: "Estatus" },
            null,
          ],
          columnDefs: [{
            targets: 2,
            render: function ( data ) {
              return fileStatus.value[data];
            }
          }, {
            targets: -1,
            data: null,
            render: function ( data ) {
              if (data.Estatus == 4) {
                return `<button v-if="file.Estatus == 4" type="button" class="btn btn-warning d-inline-block ml-2 detail">
                  <i class="bi bi-eye"></i> Ver detalle
                </button>`;
              }
              return '';
            }
          }, {
            className: "text-right", targets: 3
          }],
          ordering: false,
          info: false,
          language: {
            emptyTable: 'Sin archivos programas',
            zeroRecords: "No se encontraron resultados",
            infoEmpty: "No se encontraron resultados",
            search: "Buscar:",
            lengthMenu: "Mostrar _MENU_ entradas",
            paginate: {
              next: "Siguiente",
              previous: "Previo",
            }
          },
        });

        $('#fileProgramTable tbody').on('click', 'button.detail', function () {
          showErrorDetails(fileProgramTable.value.row($(this).parents('tr')).data());
        });
      });
    }

    function loadList() {
      fileProgramTable.value.ajax.reload();
    }

    function showCreate() {
      $('#fileProgramFormModal').modal('show');
    }

    function showErrorDetails(item) {
      itemSelected.value = item;
      $('#fileProgramErrorDetailsModal').modal('show');
    }

    return {
      fileStatus,
      itemSelected,
      loadList,
      showCreate,
      showErrorDetails,
    }
  }
}
</script>

<style lang="scss">
th {
  &.actions {
    width: 14rem;
  }
}

#fileProgramTable_length,
#fileProgramTable_filter {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

#fileProgramTable_filter {
  float: right;

  .form-control {
    margin-left: 5px;
  }
}
</style>
