import CONSTANS from '@/util/constants'

export default {
  delete (ids) {
    return window.axios.delete(`${CONSTANS.API_URL}/buffer`, {
      data: {
        'ids_eliminar': ids,
      },
    })
  },
}
