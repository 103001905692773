<template>
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-block">
      <h4 class="text-dark">Plantas</h4>
    </div>

    <div class="col-auto ml-auto text-right mt-n1">
      <button type="button" class="btn btn-primary" @click="showCreate">
        <i class="bi bi-plus"></i> Crear
      </button>
    </div>
  </div>

  <table id="floorTable" class="table table-sm table-striped bg-white" style="width:100%">
    <thead class="thead-dark">
      <tr>
        <th class="font-weight-normal">Id</th>
        <th class="font-weight-normal">Nombre</th>
        <th class="actions"></th>
      </tr>
    </thead>
  </table>

  <modal-form
    :form="itemSelected"
    @store="loadList"
  ></modal-form>

  <delete-confirmation
    :form="itemSelected"
    @deleted="loadList"
  ></delete-confirmation>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import DeleteConfirmation from '@/components/Floor/DeleteConfirmation';
import ModalForm from '@/components/Floor/ModalForm';
import { onMounted, reactive, ref, nextTick } from 'vue';
import CONSTANS from '@/util/constants';
import actions from '@/util/actions';
import $ from 'jquery';

export default {
  components: {
    'modal-form': ModalForm,
    'delete-confirmation': DeleteConfirmation,
  },

  setup() {
    const alert = reactive({
      message: "",
      title: "",
    })
    const itemSelected = ref({})

    const floorTable = ref(null)

    onMounted(() => {
      initTable();
    })

    function initTable() {
      nextTick(() => {
        floorTable.value = $('#floorTable').DataTable({
          ajax:  {
            url: `${CONSTANS.API_URL}/plantas`,
            dataSrc: 'result',
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", `Bearer ${localStorage.token}`);
            },
            error: function(request) {
              if (request.status == 401) {
                actions.logout();
              }

              alert.title = "Error al buscar";
              alert.message = request.response ? request.response.data.msg : 'Error en la petición';
              $('#toast').toast('show');
            },
          },
          columns: [
            { className: 'font-weight-light', data: "ID" },
            { className: 'font-weight-light', data: "Nombre" },
            null,
          ],
          columnDefs: [{
            targets: -1,
            data: null,
            defaultContent: `<button type="button" class="btn btn-info d-inline-block edit">
            <i class="bi bi-pencil-square"></i>
            </button>
            <button type="button" class="btn btn-danger d-inline-block ml-2 delete">
              <i class="bi bi-x"></i>
            </button>`
          }, {
            className: "text-right", targets: 2
          }],
          ordering: false,
          info: false,
          language: {
            emptyTable: 'Sin plantas',
            zeroRecords: "No se encontraron resultados",
            infoEmpty: "No se encontraron resultados",
            search: "Buscar:",
            lengthMenu: "Mostrar _MENU_ entradas",
            paginate: {
              next: "Siguiente",
              previous: "Previo",
            }
          },
        });

        $('#floorTable tbody').on('click', 'button.edit', function () {
          showEdit(floorTable.value.row($(this).parents('tr')).data());
        });

        $('#floorTable tbody').on('click', 'button.delete', function () {
          showDelete(floorTable.value.row($(this).parents('tr')).data());
        });
      });
    }

    function loadList() {
      floorTable.value.ajax.reload();
    }

    function showCreate() {
      itemSelected.value = {};
      $('#floorFormModal').modal('show');
    }

    function showEdit(item) {
      itemSelected.value = item;
      $('#floorFormModal').modal('show');
    }

    function showDelete(item) {
      itemSelected.value = item;
      $('#floorDeleteModal').modal('show');
    }

    return {
      itemSelected,
      loadList,
      showCreate,
      showEdit,
      showDelete,
    }
  }
}
</script>

<style lang="scss">
th {
  &.actions {
    width: 14rem;
  }
}

#floorTable_length,
#floorTable_filter {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

#floorTable_filter {
  float: right;

  .form-control {
    margin-left: 5px;
  }
}
</style>
