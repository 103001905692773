<template>
  <div class="modal fade" id="productionLineFormModal" tabindex="-1" aria-labelledby="productionLineFormModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="productionLineFormModalLabel">Línea de producción</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <v-form
          ref="modalForm"
          @submit="store"
          :validation-schema="formSchema"
          :initial-values="formValues"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="mb-3">
              <label for="nameInput" class="form-label">Nombre</label>

              <v-field
                name="nombre"
                as="input"
                type="text"
                id="nameInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.nombre }}</span>
            </div>

            <div class="mb-3">
              <label for="floorInput" class="form-label">Planta</label>

              <v-field
                name="planta_id"
                as="select"
                id="floorInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona una planta</option>
                <option
                  v-for="floor in floors"
                  :key="floor.ID"
                  :value="floor.ID"
                >
                    {{ floor.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.planta_id }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-primary">
              Guardar
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIFloor from '@/api/floor';
import APIProductionLine from '@/api/production_line';
import { Form as VForm, Field as VField } from 'vee-validate';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { onMounted, reactive, ref, watchEffect, getCurrentInstance } from 'vue';
import $ from 'jquery'

setLocale({
  mixed: {
    required: 'El campo es requerido',
  },
});

export default {
  props: {
    form: Object,
  },

  components: {
    VField,
    VForm,
  },

  emits: ['store'],

  setup(props, { emit }) {
    const internalInstance = getCurrentInstance()

    const alert = reactive({
      message: "",
      title: "",
    })

    const formSchema = {
      nombre: yup.string().required(),
      planta_id: yup.string().required(),
    }

    const formValues = reactive({
      id: '',
      nombre: '',
      planta_id: null,
    })

    const floors = ref([])

    onMounted(() => {
      APIFloor.get()
        .then((response) => {
          floors.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar plantas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    })

    function store(values) {
      props.form.ID ? update(props.form.ID, values) : save(values);
    }

    function save(values) {
      values.planta_id = parseInt(values.planta_id);

      APIProductionLine.post(values)
        .then(() => {
          $('#productionLineFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al crear";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    function update(id, values) {
      values.planta_id = parseInt(values.planta_id);

      APIProductionLine.patch(id, values)
        .then(() => {
          $('#productionLineFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al actualizar";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    watchEffect(() => {
      if (internalInstance.refs.modalForm) {
        internalInstance.refs.modalForm.resetForm()
      }

      if (props.form.ID != undefined) {
        formValues.id = props.form.ID;
        formValues.nombre = props.form.Nombre;
        formValues.planta_id = props.form.PlantaID;
      } else {
        formValues.id = '';
        formValues.nombre = '';
        formValues.planta_id = '';
      }
    })

    return {
      alert,
      floors,
      formSchema,
      formValues,
      store,
      save,
      update,
      internalInstance,
    }
  }
}
</script>
