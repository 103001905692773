<template>
  <div
    id="toast"
    class="position-fixed mx-3 p-3 alert alert-danger fade hide"
    role="alert"
    aria-live="assertive"
    aria-atomic="true"
    data-delay="3000"
  >
    <div>
      <strong class="mr-auto">{{ config.title }}</strong>
      <button type="button" class="ml-2 mb-1 close" data-dismiss="toast" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div>
      {{ config.message }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default: function() {
        return {
          message: "",
          title: "",
        };
      },
    },
  },
}
</script>

<style lang="scss">
#toast {
  top: 56px;
  right: 0;
  z-index: 1051;

  &.hide {
    display: none;
  }
}
</style>
