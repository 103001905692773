import CONSTANS from '@/util/constants'

export default {
  get () {
    return window.axios.get(`${CONSTANS.API_URL}/usuarios`)
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/usuario`, params)
  },

  patch (id, params) {
    return window.axios.post(`${CONSTANS.API_URL}/actualiza_usuario/${id}`, params)
  },

  delete (id) {
    return window.axios.delete(`${CONSTANS.API_URL}/elimina_usuario/${id}`)
  },
}
