const googleAuth = (function () {
  function installClient() {
    return new Promise((resolve) => {
      let script = document.createElement('script');
      script.src = 'https://accounts.google.com/gsi/client';
      script.onreadystatechange = script.onload = function () {
        if (!script.readyState || /loaded|complete/.test(script.readyState)) {
          setTimeout(function () {
            resolve()
          }, 500)
        }
      }
      document.getElementsByTagName('head')[0].appendChild(script);
    })
  }

  function Auth() {
    if (!(this instanceof Auth)) {
      return new Auth();
    }

    this.load = () => {
      installClient();
    };

    this.validateClient = () => {
      return installClient();
    };
  }

  return new Auth()
})();

export default {
  install: (app) => {
    googleAuth.load();
    app.config.globalProperties.$googleAuth = googleAuth;
  }
}
