<template>
  <div class="modal fade" id="fileProgramFormModal" tabindex="-1" aria-labelledby="fileProgramFormModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fileProgramFormModalLabel">Archivo programa</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <v-form
          ref="modalForm"
          @submit="store"
          :validation-schema="formSchema"
          :initial-values="formValues"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="mb-3">
              <label for="floorInput" class="form-label">Planta</label>

              <v-field
                name="planta_id"
                as="select"
                id="floorInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona una planta</option>
                <option
                  v-for="floor in floors"
                  :key="floor.ID"
                  :value="floor.ID"
                >
                    {{ floor.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.planta_id }}</span>
            </div>

            <div class="mb-3">
              <label for="descriptionInput" class="form-label">Descripción</label>

              <v-field
                name="descripcion"
                as="input"
                type="text"
                id="descriptionInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.descripcion }}</span>
            </div>

            <div class="mb-3">
              <label for="fileInput" class="form-label">Archivo</label>

              <v-field
                name="archivo"
                type="file"
                id="fileInput"
                rules="size:10000"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.archivo }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-primary">
              Subir
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIFloor from '@/api/floor';
import APIFileProgram from '@/api/file_program';
import { Form as VForm, Field as VField } from 'vee-validate';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { getCurrentInstance, onMounted, reactive, ref } from 'vue';
import $ from 'jquery'

setLocale({
  mixed: {
    required: 'El campo es requerido',
  },
});

export default {
  components: {
    VField,
    VForm,
  },

  emits: ['store'],

  setup(props, { emit }) {
    const internalInstance = getCurrentInstance()

    const alert = reactive({
      message: "",
      title: "",
    })

    const formSchema = {
      archivo: yup.mixed().required().test(
        "type",
        "Solo se puede subir archivos con la extensión .xlsx",
        (value) => !value || (value && value[0].type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
      ),
      planta_id: yup.string().required(),
      descripcion: yup.string().required(),
    }

    const formValues = reactive({
      archivo: null,
      planta_id: null,
      descripcion: '',
    })

    const floors = ref([])

    onMounted(() => {
      APIFloor.get()
        .then((response) => {
          floors.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar plantas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })

      if (internalInstance.refs.modalForm) {
        internalInstance.refs.modalForm.resetForm()
      }

      $('#myModal').on('show.bs.modal', function () {
        formValues.archivo = null;
        formValues.planta_id = null;
        formValues.descripcion = '';
      });
    })

    function store(values) {
      const formData = new FormData();
      formData.append('archivo', values.archivo[0])
      formData.append('planta_id', values.planta_id)
      formData.append('descripcion', values.descripcion)
      formData.append('usuario_id', parseInt(localStorage.usuario_id))

      APIFileProgram.post(formData)
        .then(() => {
          $('#fileProgramFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al crear";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    return {
      alert,
      floors,
      formSchema,
      formValues,
      store,
      internalInstance,
    }
  }
}
</script>
