import CONSTANS from '@/util/constants'

export default {
  get () {
    return window.axios.get(`${CONSTANS.API_URL}/empresas`)
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/empresa`, params)
  },

  patch (id, params) {
    return window.axios.post(`${CONSTANS.API_URL}/actualiza_empresa/${id}`, params)
  },

  delete (id) {
    return window.axios.delete(`${CONSTANS.API_URL}/elimina_empresa/${id}`)
  },
}
