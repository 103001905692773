import actions from '@/util/actions';
window.axios = require('axios')

if (localStorage.token) {
  window.axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.token}`;
}

// Add a response interceptor
window.axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (error.response.status == 401) {
    actions.logout()
  }

  return Promise.reject(error);
});

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.css'

import 'datatables.net-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-responsive-bs4'

import googleOAuth from '@/util/google_oauth2';
import Toast from '@/components/Toast';

const app = createApp(App).use(store).use(router).use(googleOAuth)

app.component('toast', Toast)

app.mount('#app')
