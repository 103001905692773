<template>
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-block">
      <h4 class="text-dark">Calendario Laboral</h4>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <div class="mb-3">
        <label for="`floorInput" class="form-label">Año</label>

        <select id="`floorInput" class="form-control" v-model="filters.year">
          <option value="" disabled>Selecciona un año</option>
          <option
            v-for="year in years"
            :key="year"
            :value="year"
          >
            {{ year }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="`floorInput" class="form-label">Mes</label>

        <select id="`floorInput" class="form-control" v-model="filters.month">
          <option value="" disabled>Selecciona un mes</option>
          <option
            v-for="month in months"
            :key="month.id"
            :value="month.id"
          >
            {{ month.name }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="`floorInput" class="form-label">Planta</label>

        <select id="`floorInput" class="form-control" v-model="filters.floorId">
          <option value="null" disabled>Selecciona una planta</option>
          <option
            v-for="floor in floors"
            :key="floor.ID"
            :value="floor.ID"
          >
            {{ floor.Nombre }}
          </option>
        </select>
      </div>

      <div class="mb-3">
        <label for="productionLineInput" class="form-label">Línea de producción</label>

        <select id="productionLineInput" class="form-control" v-model="filters.productionLineId">
          <option value="null" disabled>Selecciona una línea de producción</option>
          <option value="0">Todas</option>
          <option
            v-for="productionLine in productionLinesFilter"
            :key="productionLine.ID"
            :value="productionLine.ID"
          >
            {{ productionLine.Nombre }}
          </option>
        </select>
      </div>

      <div class="mb-3" align="right">
        <button class="btn btn-primary" @click="filter">
          Filtrar
        </button>
      </div>

      <div class="card">
        <div class="card-body">
          <h5 class="card-title">Totales</h5>
          <label class="form-label d-block">Días laborables mes: <b>{{ totalMonth }}</b></label>
          <label class="form-label">Días laborables fecha: <b>{{ totalDate }}</b></label>
        </div>
      </div>
    </div>
    <div class="col-9 justify-content-center">
      <div id='calendar'></div>
    </div>
  </div>

  <toast :config="alert"></toast>

  <confirmation
    :form="itemSelected"
    @updated="filter"
  ></confirmation>
</template>

<script>
// @ is an alias to /src
import Confirmation from '@/components/WorkCalendar/Confirmation';
import APIProductionLine from '@/api/production_line';
import APIWorkCalendar from '@/api/work_calendar';
import { Calendar } from '@fullcalendar/core';
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from '@fullcalendar/core/locales/es';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import { onMounted, reactive, ref, watch } from 'vue';
import APIFloor from '@/api/floor';
import $ from 'jquery';

export default {
  components: {
    'confirmation': Confirmation,
  },

  setup() {
    const productionLinesFilter = ref([])
    const productionLines = ref([])
    const itemSelected = ref({
      day: '',
      calendar: {},
    })
    const eventsAdded = ref([])
    const calendar = ref(null)
    const years = ref([])
    const calendarList = ([])
    const floors = ref([])
    const events = ref([])
    const totalMonth = ref(0)
    const totalDate = ref(0)
    const filters = reactive({
      year: new Date().getFullYear(),
      month: new Date().getMonth()+1,
      floorId: null,
      productionLineId: null,
    })
    const months = ref([
      { id: 1, name: 'Enero' },
      { id: 2, name: 'Febrero' },
      { id: 3, name: 'Marzo' },
      { id: 4, name: 'Abril' },
      { id: 5, name: 'Mayo' },
      { id: 6, name: 'Junio' },
      { id: 7, name: 'Julio' },
      { id: 8, name: 'Agosto' },
      { id: 9, name: 'Septiembre' },
      { id: 10, name: 'Octubre' },
      { id: 11, name: 'Noviembre' },
      { id: 12, name: 'Diciembre' },
    ])
    const alert = reactive({
      message: "",
      title: "",
    })

    onMounted(() => {
      const currentYear = new Date().getFullYear();

      for (let i = 2021; i <= currentYear; i++) {
        years.value.push(i);
      }

      APIFloor.get()
        .then((response) => {
          floors.value = response.data.result.filter(element => element.EmpresaID == localStorage.empresa_id);
        })
        .catch((error) => {
          alert.title = "Error al buscar plantas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });

      APIProductionLine.get()
        .then((response) => {
          productionLines.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar líneas de producción";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })

      let calendarEl = document.getElementById('calendar');
      calendar.value = new Calendar(calendarEl, {
        locale: esLocale,
        plugins: [ dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin ],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'title',
          right: '',
        },
        weekends: true,
        dateClick: handleDateSelect,
      });

      calendar.value.render();
    })

    function handleDateSelect(selectInfo) {
      if (!filters.floorId || filters.productionLineId == null) {
        alert.title = "";
        alert.message = "Primero debes filtrar para poder modificar si un día si es laboral o no.";

        $('#toast').toast('show');
        return;
      }

      var calendar = calendarList.value.filter(element => {
        var date = element.Fecha.split('T');

        return date[0] == selectInfo.dateStr;
      });

      itemSelected.value = {
        day: selectInfo.dateStr,
        calendar: calendar[0],
      };

      $('#workCalendarModal').modal('show');
    }

    function filter() {
      if (!filters.floorId || filters.productionLineId == null) {
        alert.title = "Error al filtrar";
        alert.message = "Debes seleccionar una planta y línea de producción para poder filtrar.";

        $('#toast').toast('show');
        return;
      }

      APIWorkCalendar.get({
        año: filters.year,
        mes: filters.month,
        planta_id: filters.floorId,
        linea_produccion_id: filters.productionLineId,
      }).then((response) => {
        calendarList.value = response.data.result;
        totalMonth.value = response.data.dias_laborables_mes;
        totalDate.value = response.data.dias_laborables_fecha;
        loadEvents();
      }).catch((error) => {
        alert.title = "Error al buscar";
        alert.message = error.response ? error.response.data.msg : 'Error en la petición';
        $('#toast').toast('show');
      });
    }

    function loadEvents() {
      var month = filters.month < 10 ? `0${filters.month}` : filters.month;

      calendar.value.gotoDate(`${filters.year}-${month}-01`)

      eventsAdded.value.forEach(event => {
        event.remove()
      });

      eventsAdded.value = [];

      var registerEvents = calendarList.value.filter(element => element.Laborable == false);

      registerEvents.forEach(element => {
        var date = element.Fecha.split('T');

        var eventAdded = calendar.value.addEvent({
          start: date[0],
          display: 'background',
          color: '#6c757d',
        });
        eventsAdded.value.push(eventAdded)
      });
    }

    watch(
      () => filters.floorId,
      (value) => {
        productionLinesFilter.value = productionLines.value.filter(element => element.PlantaID == value)
        filters.productionLineId = 0;
      }
    )

    return {
      alert,
      filters,
      years,
      months,
      floors,
      productionLinesFilter,
      calendar,
      itemSelected,
      events,
      filter,
      totalMonth,
      totalDate,
    }
  }
}
</script>

<style lang="scss">
#calendar {
  width: 700px;
}
</style>
