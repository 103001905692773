<template>
  <router-view v-if="routeName == 'Login' || routeName == undefined"/>

  <div
    v-else
    class="d-flex align-items-stretch w-100"
  >
    <nav
      :class="{ 'collapsed': collapsed }"
      class="sidebar min-vh-100"
    >
      <div class="sidebar-content position-relative">
        <a class="sidebar-brand d-block text-center py-3 px-4" href="/">
          <img :src="require(`@/assets/${businessName}.png`)" :alt="businessName" height="40">
        </a>

        <ul class="sidebar-nav list-unstyled">
          <li class="sidebar-header px-4 pt-4 pb-1">
            Buffer de Producción
          </li>
          <li
            v-if="hasMenu('Empresas')"
            :class="{ 'active': routeName == 'Empresas' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none active" to="/empresas">
              Empresas
            </router-link>
          </li>
          <li
            v-if="hasMenu('Plantas')"
            :class="{ 'active': routeName == 'Plantas' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/plantas">
              Plantas
            </router-link>
          </li>
          <li
            v-if="hasMenu('Linea Producción')"
            :class="{ 'active': routeName == 'Linea Producción' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/lineas-produccion">
              Líneas de Producción
            </router-link>
          </li>
          <li
            v-if="hasMenu('Categorias')"
            :class="{ 'active': routeName == 'Categorias' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/categorias">
              Categorías
            </router-link>
          </li>
          <li
            v-if="hasMenu('Usuarios')"
            :class="{ 'active': routeName == 'Usuarios' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/usuarios">
              Usuarios
            </router-link>
          </li>
          <li
            v-if="hasMenu('Archivos Programa')"
            :class="{ 'active': routeName == 'Archivos Programa' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/archivos-programas">
              Archivos Programas
            </router-link>
          </li>
          <li
            v-if="hasMenu('Calendario Laboral')"
            :class="{ 'active': routeName == 'Calendario Laboral' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/calendario-laboral">
              Calendario Laboral
            </router-link>
          </li>
          <li
            v-if="hasMenu('Buffer')"
            :class="{ 'active': routeName == 'Buffer' }"
            class="sidebar-item"
          >
            <router-link class="sidebar-link d-block font-weight-normal text-decoration-none" to="/">
              Buffer
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div class="main d-flex flex-column w-100 min-vh-100">
      <nav
        class="navbar navbar-expand navbar-light navbar-bg"
      >
        <a class="sidebar-toggle" @click="collapsed = !collapsed">
          <i class="hamburger align-self-center"></i>
        </a>

        <div class="d-none d-sm-inline-block pl-3">
          Buffer de Producción
        </div>

        <div class="navbar-collapse collapse">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <a class="nav-icon dropdown-toggle d-inline-block d-sm-none" href="#" data-toggle="dropdown">
                <i class="bi bi-gear" width="24" height="24"></i>
              </a>

              <a class="nav-link dropdown-toggle d-none d-sm-inline-block" href="#" data-toggle="dropdown" aria-expanded="false">
                <img :src="avatar" class="avatar img-fluid rounded-circle mr-1" alt="Avatar" height="40" width="40">
                <span class="text-dark">{{ email }}</span>
              </a>

              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#" @click="logout()">Salir</a>
              </div>
            </li>
          </ul>
        </div>
      </nav>

      <main class="content bg-light">
        <div class="container-fluid p-0">
          <router-view/>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { useRouter, useRoute } from 'vue-router'
import { ref, computed } from 'vue'

export default {
  setup() {
    const collapsed = ref(true)
    const route = useRoute()
    const router = useRouter()
    const routeName = computed(() => route.name)
    const avatar = ref(localStorage.avatar)
    const email = ref(localStorage.email)
    const businessName = ref(localStorage.empresa_nombre)
    const logout = async () => {
      try {
        localStorage.clear();
        router.push({ name: 'Login' });
      } catch (error) {
        console.error(error);
      }
    }

    function hasMenu(menu) {
      return localStorage.menu.split(',').find(element => element == menu)
    }

    return {
      avatar,
      email,
      businessName,
      collapsed,
      routeName,
      logout,
      hasMenu,
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  min-width: 260px;
  max-width: 260px;
  transition: margin-left .35s ease-in-out,left .35s ease-in-out,margin-right .35s ease-in-out,right .35s ease-in-out;
  direction: ltr;
  background: #293042;

  &.collapsed {
    margin-left:-260px
  }

  .sidebar-content {
    .sidebar-nav {
      .sidebar-header {
        font-size: .75rem;
        color: #ced4da;
      }

      a.sidebar-link {
        padding: .625rem 1.625rem;
        color: rgba(233,236,239,.5);
      }

      .sidebar-link:focus {
        outline: 0;
      }

      .sidebar-link:hover {
        color: rgba(233,236,239,.75);
      }

      .sidebar-item.active .sidebar-link:hover,
      .sidebar-item.active>.sidebar-link {
        color:#e9ecef;
      }
    }
  }
}

.main {
  .content {
    padding: 2.5rem 2.5rem 1.5rem;
    flex: 1;
    direction: ltr;
  }

  .sidebar-toggle {
    cursor: pointer;
    width: 26px;
    height: 26px;
    display: flex;

    .hamburger,
    .hamburger:after,
    .hamburger:before {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 24px;
      background: #495057;
      display: block;
      content: "";
      transition: background .1s ease-in-out,color .1s ease-in-out;
    }

    .hamburger {
      position: relative;
    }

    .hamburger:before {
      top: -8px;
      width: 20px;
      position: absolute;
    }

    .hamburger:after {
      bottom: -8px;
      width: 16px;
      position: absolute;
    }
  }

  .sidebar-toggle:hover .hamburger,
  .sidebar-toggle:hover .hamburger:after,
  .sidebar-toggle:hover .hamburger:before {
    background: #3f80ea;
  }

  .avatar {
    margin-top: -15px;
    margin-bottom: -15px;
  }
}
</style>
