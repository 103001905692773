<template>
  <div class="modal fade" id="workCalendarModal" tabindex="-1" aria-labelledby="workCalendarModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="workCalendarModalLabel">Actualizar día laboral</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <div class="mb-3">
              <label>¿Estás seguro que quieres actualizar el día {{ form.day }} a {{ form.calendar.Laborable ? 'no' : '' }} laborable?</label>

              <div class="form-group form-check">
                <input type="checkbox" class="form-check-input" id="allFlootsCheck" v-model="allFloors">
                <label class="form-check-label" for="allFlootsCheck">Actualizar para todas las plantas</label>
              </div>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-danger" @click="updateCalendar">
              Guardar
            </button>
          </div>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIWorkCalendar from '@/api/work_calendar';
import { reactive, ref } from 'vue';
import $ from 'jquery';

export default {
  props: {
    form: {
      type: Object,
      default: function() {
        return {
          day: '',
          calendar: {},
        }
      },
    },
  },

  emits: ['updated'],

  setup(props, { emit }) {
    const alert = reactive({
      message: "",
      title: "",
    })
    const allFloors = ref(false)

    function updateCalendar() {
      APIWorkCalendar.post({
        "calendario_id": props.form.calendar.ID,
        "laborable": !props.form.calendar.Laborable,
        "para_todas_plantas": allFloors.value,
      }).then(() => {
        $('#workCalendarModal').modal('hide');
        emit('updated');
      })
      .catch((error) => {
        alert.title = "Error al actualizar";
        alert.message = error.response ? error.response.data.msg : 'Error en la petición';
        $('#toast').toast('show');
      });
    }

    return {
      alert,
      allFloors,
      updateCalendar,
    }
  }
}
</script>
