import { createRouter, createWebHistory } from 'vue-router'

import Login from '@/views/Login';
import Buffer from '@/views/Buffer';
import Businesses from '@/views/Businesses';
import Floors from '@/views/Floors';
import ProductionLines from '@/views/ProductionLines';
import Categories from '@/views/Categories';
import Users from '@/views/Users';
import FilesPrograms from '@/views/FilesPrograms';
import WorkCalendar from '@/views/WorkCalendar';

const routes = [
  {
    path: '/login',
    name: 'Login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited
    // component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
    component: Login
  },
  {
    path: '/',
    name: 'Buffer',
    // component: () => import(/* webpackChunkName: "buffer" */ '../views/Buffer.vue')
    component: Buffer
  },
  {
    path: '/empresas',
    name: 'Empresas',
    // component: () => import(/* webpackChunkName: "businesses" */ '../views/Businesses.vue')
    component: Businesses
  },
  {
    path: '/plantas',
    name: 'Plantas',
    // component: () => import(/* webpackChunkName: "floors" */ '../views/Floors.vue')
    component: Floors
  },
  {
    path: '/lineas-produccion',
    name: 'Linea Producción',
    // component: () => import(/* webpackChunkName: "productionLines" */ '../views/ProductionLines.vue')
    component: ProductionLines
  },
  {
    path: '/categorias',
    name: 'Categorias',
    // component: () => import(/* webpackChunkName: "categories" */ '../views/Categories.vue')
    component: Categories
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    // component: () => import(/* webpackChunkName: "users" */ '../views/Users.vue')
    component: Users
  },
  {
    path: '/archivos-programas',
    name: 'Archivos Programa',
    // component: () => import(/* webpackChunkName: "filesPrograms" */ '../views/FilesPrograms.vue')
    component: FilesPrograms
  },
  {
    path: '/calendario-laboral',
    name: 'Calendario Laboral',
    // component: () => import(/* webpackChunkName: "filesPrograms" */ '../views/FilesPrograms.vue')
    component: WorkCalendar
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !localStorage.token) {
    next({ name: 'Login' })
    return
  }

  if(to.name == 'Login' || localStorage.menu.split(',').find(element => element == to.name)) {
    next()
    return
  }

  next({ name: from.name })
})

export default router
