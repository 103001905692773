import CONSTANS from '@/util/constants'

export default {
  get (params) {
    return window.axios.get(`${CONSTANS.API_URL}/calendarios`, {
      params: params
    })
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/calendarios`, params)
  },
}
