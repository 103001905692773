<template>
  <div class="modal fade" id="categoryFormModal" tabindex="-1" aria-labelledby="categoryFormModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="categoryFormModalLabel">Categoría</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <v-form
          ref="modalForm"
          @submit="store"
          :validation-schema="formSchema"
          :initial-values="formValues"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="mb-3">
              <label for="valueInput" class="form-label">Valor</label>

              <v-field
                name="valor"
                as="input"
                type="text"
                id="valueInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.valor }}</span>
            </div>

            <div class="mb-3">
              <label for="descriptionInput" class="form-label">Descripción</label>

              <v-field
                name="descripcion"
                as="input"
                type="text"
                id="descriptionInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.descripcion }}</span>
            </div>

            <div class="mb-3">
              <label for="`productionLineInput" class="form-label">Línea de producción</label>

              <v-field
                name="linea_produccion_id"
                as="select"
                id="`productionLineInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona una línea de producción</option>
                <option
                  v-for="productionLine in productionLines"
                  :key="productionLine.ID"
                  :value="productionLine.ID"
                >
                    {{ productionLine.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.linea_produccion_id }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-primary">
              Guardar
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APICategory from '@/api/category';
import APIProductionLine from '@/api/production_line';
import { Form as VForm, Field as VField } from 'vee-validate';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { onMounted, reactive, ref, watchEffect, getCurrentInstance } from 'vue';
import $ from 'jquery';

setLocale({
  mixed: {
    required: 'El campo es requerido',
  },
});

export default {
  props: {
    form: Object,
  },

  components: {
    VField,
    VForm,
  },

  emits: ['store'],

  setup(props, { emit }) {
    const internalInstance = getCurrentInstance()

    const alert = reactive({
      message: "",
      title: "",
    })

    const formSchema = {
      valor: yup.string().required(),
      descripcion: yup.string().required(),
      linea_produccion_id: yup.string().required(),
    }

    const formValues = reactive({
      id: '',
      valor: '',
      descripcion: '',
      linea_produccion_id: null,
    })

    const productionLines = ref([])

    onMounted(() => {
      APIProductionLine.get()
        .then((response) => {
          productionLines.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar líneas de producción";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    })

    function store(values) {
      props.form.ID ? update(props.form.ID, values) : save(values);
    }

    function save(values) {
      values.linea_produccion_id = parseInt(values.linea_produccion_id);

      APICategory.post(values)
        .then(() => {
          $('#categoryFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al crear";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    function update(id, values) {
      values.linea_produccion_id = parseInt(values.linea_produccion_id);

      APICategory.patch(id, values)
        .then(() => {
          $('#categoryFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al actualizar";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    watchEffect(() => {
      if (internalInstance.refs.modalForm) {
        internalInstance.refs.modalForm.resetForm()
      }

      if (props.form.ID != undefined) {
        formValues.id = props.form.ID;
        formValues.valor = props.form.Valor;
        formValues.descripcion = props.form.Descripcion;
        formValues.linea_produccion_id = props.form.LineaProduccionID;
      } else {
        formValues.id = '';
        formValues.valor = '';
        formValues.descripcion = '';
        formValues.linea_produccion_id = '';
      }
    })

    return {
      productionLines,
      alert,
      formSchema,
      formValues,
      store,
      save,
      update,
      internalInstance,
    }
  }
}
</script>
