import CONSTANS from '@/util/constants'

export default {
  get () {
    return window.axios.get(`${CONSTANS.API_URL}/plantas`)
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/planta`, params)
  },

  patch (id, params) {
    return window.axios.post(`${CONSTANS.API_URL}/actualizar_planta/${id}`, params)
  },

  delete (id) {
    return window.axios.delete(`${CONSTANS.API_URL}/eliminar_planta/${id}`)
  },
}
