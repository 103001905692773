<template>
  <div class="d-flex flex-column justify-content-center align-items-center bg-dark background-image">
    <img class="align-self-center mb-3" :src="require('@/assets/sisamex-blanco.png')" alt="Sisamex" height="40">

    <div class="card">
      <div class="card-body p-5" align="center">
        <p class="card-title font-weight-normal h4">¡Bienvenido!</p>

        <p class="card-text text-secondary">Accede con tu cuenta de Gmail.</p>

        <div align="center">
          <button type="button" class="btn btn-outline-primary rounded-pill" @click="login">
            <div class="px-1 d-inline">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 48 48">
                <g><path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path><path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path><path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path><path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path><path fill="none" d="M0 0h48v48H0z"></path></g>
              </svg>
            </div>

            <span class="sing-in">
              Accede con Google
            </span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APILogin from '@/api/login';
import { getCurrentInstance, onMounted, reactive, ref } from 'vue';
import CONSTANS from '@/util/constants';
import $ from 'jquery';

export default {
  setup() {
    const internalInstance = getCurrentInstance()
    const googleClient = ref()
    const alert = reactive({
      message: "",
      title: "",
    })

    onMounted(() => {
      internalInstance.appContext.config.globalProperties.$googleAuth.validateClient().then(() => {
        googleClient.value = google.accounts.oauth2.initTokenClient({
          client_id: CONSTANS.GOOGLE_CLIENT_ID,
          scope: 'https://www.googleapis.com/auth/userinfo.email \
            https://www.googleapis.com/auth/userinfo.profile',
          ux_mode: 'popup',
          callback: (tokenResponse) => {
            if (tokenResponse && tokenResponse.access_token) {
              if (google.accounts.oauth2.hasGrantedAnyScope(tokenResponse, 'https://www.googleapis.com/auth/userinfo.email')) {
                axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?alt=json`, {
                  headers: { Authorization: `Bearer ${tokenResponse.access_token}` }
                }).then(userResponse => {
                  const googleUser = userResponse.data;

                  APILogin.post({
                    email: googleUser.email,
                    token: tokenResponse.access_token,
                  }).then(response => {
                    localStorage.avatar = googleUser.picture;
                    localStorage.email = googleUser.email;
                    localStorage.token = tokenResponse.access_token;
                    localStorage.usuario_id = response.data.usuario_id;
                    localStorage.empresa_id = response.data.empresa_id;
                    localStorage.empresa_nombre = response.data.empresa_nombre;
                    localStorage.menu = response.data.menu;
                    localStorage.tipo_usuario = response.data.tipo_usuario;

                    window.location = '/';
                  }).catch(error => {
                    alert.title = "Error en login";
                    alert.message = error.response ? error.response.data.msg : 'Error en la petición';
                    $('#toast').toast('show');
                  });
                });
              }
            }
          },
        });
      });
    })

    function login() {
      googleClient.value.requestAccessToken();
    }

    return {
      alert,
      login,
    }
  }
}
</script>

<style lang="scss">
.background-image {
  position: relative;
  height: 100vh;

  &:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-image: url('../assets/background.jpg');
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
  }

  img {
    z-index: 1;
  }
}
</style>
