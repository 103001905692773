<template>
  <div class="modal fade" id="bufferDeleteModal" tabindex="-1" aria-labelledby="bufferDeleteModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bufferDeleteModalLabel">Eliminar Buffers</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="mb-3" align="center">
            <i class="bi bi-exclamation-circle text-danger display-1 d-block"></i>
            ¿Estás seguro que quieres eliminar los Buffer seleccionados?
            Cantidad: {{ ids.length }}
          </div>
        </div>

        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Cancelar
          </button>

          <button class="btn btn-danger" @click="deleteItems">
            Eliminar
          </button>
        </div>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIBuffer from '@/api/buffer';
import { reactive } from 'vue';
import $ from 'jquery'

export default {
  props: {
    ids: Object,
  },

  emits: ['deleted'],

  setup(props, { emit }) {
    const alert = reactive({
      message: "",
      title: "",
    })

    function deleteItems() {
      APIBuffer.delete(props.ids)
        .then(() => {
          $('#bufferDeleteModal').modal('hide');
          emit('deleted');
        })
        .catch((error) => {
          alert.title = "Error al eliminar";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    }

    return {
      alert,
      deleteItems,
    }
  }
}
</script>
