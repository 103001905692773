<template>
  <div class="modal fade" id="fileProgramErrorDetailsModal" tabindex="-1" aria-labelledby="fileProgramErrorDetailsModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="fileProgramErrorDetailsModalLabel">Detalle de errors de {{ form.Origen }}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
          <div class="modal-body">
            <table class="table table-striped bg-white">
              <thead class="thead-dark">
                <tr>
                  <th>Parte</th>
                  <th>Error</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="fileError in fileErrors" :key="fileError.Parte">
                  <td>{{ fileError.Parte }}</td>
                  <td>{{ fileError.Error }}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">
              Cerrar
            </button>
          </div>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIFileProgram from '@/api/file_program';
import { reactive, ref, watchEffect } from 'vue';
import $ from 'jquery'

export default {
  props: {
    form: Object,
  },

  emits: ['deleted'],

  setup(props) {
    const alert = reactive({
      message: "",
      title: "",
    })

    const fileErrors = ref([])

    watchEffect(() => {
      if (props.form.ID) {
        getErrorDetails();
      }
    })

    function getErrorDetails() {
      APIFileProgram.getErrors(props.form.ID)
        .then((response) => {
          fileErrors.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al obtener detalle";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    }

    return {
      alert,
      fileErrors,
    }
  }
}
</script>
