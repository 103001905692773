<template>
  <div class="modal fade" id="userFormModal" tabindex="-1" aria-labelledby="userFormModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="userFormModalLabel">Usuario</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <v-form
          ref="modalForm"
          @submit="store"
          :validation-schema="formSchema"
          :initial-values="formValues"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="mb-3">
              <label for="userInput" class="form-label">Usuario</label>

              <v-field
                name="usuario"
                as="input"
                type="text"
                id="userInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.usuario }}</span>
            </div>

            <div class="mb-3">
              <label for="emailInput" class="form-label">Correo electrónico</label>

              <v-field
                name="email"
                as="input"
                type="text"
                id="emailInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.email }}</span>
            </div>

            <div class="mb-3">
              <label for="nameInput" class="form-label">Nombre</label>

              <v-field
                name="nombre"
                as="input"
                type="text"
                id="nameInput"
                class="form-control"
              />

              <span class="fs-6 text-danger">{{ errors.nombre }}</span>
            </div>

            <div class="mb-3">
              <label for="authTypeInput" class="form-label">Tipo de autenticación</label>

              <v-field
                name="tipo_autt"
                as="select"
                id="authTypeInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona un tipo de autenticación</option>
                <option :value="1"> Sistemas </option>
                <option :value="2"> Google </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.tipo_autt }}</span>
            </div>

            <div class="mb-3">
              <label for="userTypeInput" class="form-label">Tipo de usuario</label>

              <v-field
                name="tipo_usuario"
                as="select"
                id="userTypeInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona un tipo de usuario</option>
                <option :value="1"> Administrador </option>
                <option :value="2"> Programador </option>
                <option :value="3"> Consultas </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.tipo_usuario }}</span>
            </div>

            <div class="mb-3">
              <label for="businessInput" class="form-label">Empresa</label>

              <v-field
                name="empresa_id"
                as="select"
                id="businessInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona una empresa</option>
                <option
                  v-for="business in businesses"
                  :key="business.ID"
                  :value="business.ID"
                >
                    {{ business.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.empresa_id }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-primary">
              Guardar
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIBusiness from '@/api/business';
import APIUser from '@/api/user';
import { Form as VForm, Field as VField } from 'vee-validate';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { onMounted, reactive, ref, watchEffect, getCurrentInstance } from 'vue';
import $ from 'jquery'

setLocale({
  mixed: {
    required: 'El campo es requerido',
  },
  string: {
    email: 'El formato debe ser de un correo electrónico',
  },
});

export default {
  props: {
    form: Object,
  },

  components: {
    VField,
    VForm,
  },

  emits: ['store'],

  setup(props, { emit }) {
    const internalInstance = getCurrentInstance()

    const alert = reactive({
      message: "",
      title: "",
    })

    const businesses = ref([])

    const formSchema = {
      usuario: yup.string().required(),
      email: yup.string().required().email(),
      nombre: yup.string().required(),
      tipo_autt: yup.number().required().positive().integer(),
      tipo_usuario: yup.number().required().positive().integer(),
      empresa_id: yup.string().required(),
    }

    const formValues = reactive({
      id: '',
      usuario: '',
      email: '',
      nombre: '',
      tipo_autt: null,
      tipo_usuario: null,
      empresa_id: null,
    })

    onMounted(() => {
      APIBusiness.get()
        .then((response) => {
          businesses.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar empresas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })
    })

    function store(values) {
      props.form.ID ? update(props.form.ID, values) : save(values);
    }

    function save(values) {
      values.tipo_autt = parseInt(values.tipo_autt);
      values.tipo_usuario = parseInt(values.tipo_usuario);
      values.empresa_id = parseInt(values.empresa_id);

      APIUser.post(values)
        .then(() => {
          $('#userFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al crear";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    function update(id, values) {
      values.empresa_id = parseInt(values.empresa_id);
      values.tipo_autt = parseInt(values.tipo_autt);
      values.tipo_usuario = parseInt(values.tipo_usuario);

      APIUser.patch(id, values)
        .then(() => {
          $('#userFormModal').modal('hide');
          emit('store');
        })
        .catch((error) => {
          alert.title = "Error al actualizar";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        });
    }

    watchEffect(() => {
      if (internalInstance.refs.modalForm) {
        internalInstance.refs.modalForm.resetForm()
      }

      if (props.form.ID != undefined) {
        formValues.id = props.form.ID;
        formValues.usuario = props.form.Usuario;
        formValues.email = props.form.Email;
        formValues.nombre = props.form.Nombre;
        formValues.tipo_autt = props.form.TipoAutt;
        formValues.tipo_usuario = props.form.TipoUsuario;
        formValues.empresa_id = props.form.EmpresaID;
      } else {
        formValues.id = '';
        formValues.usuario = '';
        formValues.email = '';
        formValues.nombre = '';
        formValues.tipo_autt = null;
        formValues.tipo_usuario = null;
        formValues.empresa_id = null;
      }
    })

    return {
      alert,
      businesses,
      formSchema,
      formValues,
      store,
      save,
      update,
      internalInstance,
    }
  }
}
</script>
