import CONSTANS from '@/util/constants'

export default {
  get () {
    return window.axios.get(`${CONSTANS.API_URL}/obtenerarchivo`)
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/subirarchivo`, params, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
  },

  getErrors (id) {
    return window.axios.post(`${CONSTANS.API_URL}/obtenererrorarchivo/${id}`)
  },
}
