import CONSTANS from '@/util/constants'

export default {
  get () {
    return window.axios.get(`${CONSTANS.API_URL}/categorias`)
  },

  post (params) {
    return window.axios.post(`${CONSTANS.API_URL}/categoria`, params)
  },

  patch (id, params) {
    return window.axios.post(`${CONSTANS.API_URL}/actualizar_categoria/${id}`, params)
  },

  delete (id) {
    return window.axios.delete(`${CONSTANS.API_URL}/eliminar_categoria/${id}`)
  },
}
