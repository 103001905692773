<template>
  <div class="modal fade" id="bufferFilterModal" tabindex="-1" aria-labelledby="bufferFilterModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="bufferFilterModalLabel">Historial</h5>

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <v-form
          ref="filterForm"
          @submit="filter"
          :validation-schema="formSchema"
          :initial-values="formValues"
          v-slot="{ errors }"
        >
          <div class="modal-body">
            <div class="mb-3">
              <label for="`yearInput" class="form-label">Año</label>

              <v-field
                name="año"
                as="select"
                id="`yearInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona un año</option>
                <option
                  v-for="year in years"
                  :key="year"
                  :value="year"
                >
                  {{ year }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.año }}</span>
            </div>

            <div class="mb-3">
              <label for="`monthInput" class="form-label">Mes</label>

              <v-field
                name="mes"
                as="select"
                id="`monthInput"
                class="form-control"
              >
                <option value="" disabled>Selecciona un mes</option>
                <option
                  v-for="month in months"
                  :key="month.id"
                  :value="month.id"
                >
                  {{ month.name }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.mes }}</span>
            </div>

            <div class="mb-3">
              <label for="`businessInput" class="form-label">Empresa</label>

              <v-field
                name="empresa_id"
                as="select"
                id="`businessInput"
                class="form-control"
                v-model="businessSelected"
              >
                <option value="" disabled>Selecciona una empresa</option>
                <option
                  v-for="business in businesses"
                  :key="business.ID"
                  :value="business.ID"
                >
                  {{ business.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.empresa_id }}</span>
            </div>

            <div class="mb-3">
              <label for="`floorInput" class="form-label">Planta</label>

              <v-field
                name="planta_id"
                as="select"
                id="`floorInput"
                class="form-control"
                v-model="floorSelected"
              >
                <option value="" disabled>Selecciona una planta</option>
                <option value="0">Todas</option>
                <option
                  v-for="floor in floorsFilter"
                  :key="floor.ID"
                  :value="floor.ID"
                >
                  {{ floor.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.planta_id }}</span>
            </div>

            <div class="mb-3">
              <label for="`productionLineInput" class="form-label">Línea de producción</label>

              <v-field
                name="linea_produccion_id"
                as="select"
                id="`productionLineInput"
                class="form-control"
                v-model="productionLineSelected"
              >
                <option value="" disabled>Selecciona una línea de producción</option>
                <option value="0">Todas</option>
                <option
                  v-for="productionLine in productionLinesFilter"
                  :key="productionLine.ID"
                  :value="productionLine.ID"
                >
                  {{ productionLine.Nombre }}
                </option>
              </v-field>

              <span class="fs-6 text-danger">{{ errors.linea_produccion_id }}</span>
            </div>
          </div>

          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">
              Cancelar
            </button>

            <button class="btn btn-primary">
              Filtrar
            </button>
          </div>
        </v-form>
      </div>
    </div>
  </div>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import APIBusiness from '@/api/business';
import APIFloor from '@/api/floor';
import APIProductionLine from '@/api/production_line';
import { Form as VForm, Field as VField } from 'vee-validate';
import { setLocale } from 'yup';
import * as yup from 'yup';
import { getCurrentInstance, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import $ from 'jquery';

setLocale({
  mixed: {
    required: 'El campo es requerido',
  },
});


export default {
  props: {
    form: Object,
  },

  components: {
    VField,
    VForm,
  },

  emits: ['filter'],

  setup(props, { emit }) {
    const alert = reactive({
      message: "",
      title: "",
    })

    const internalInstance = getCurrentInstance()
    const formSchema = {
      año: yup.number().required().positive().integer(),
      mes: yup.number().required().positive().integer(),
      empresa_id: yup.number().required().positive().integer(),
      planta_id: yup.number().required().integer(),
      linea_produccion_id: yup.number().required().integer(),
    }

    const formValues = reactive({
      año: null,
      mes: null,
      empresa_id: null,
      planta_id: null,
      linea_produccion_id: null,
    })

    const businessSelected = ref(0)
    const floorSelected = ref(0)
    const productionLineSelected = ref(0)

    const businesses = ref([])
    const floors = ref([])
    const floorsFilter = ref([])
    const months = ref([
      { id: 1, name: 'Enero' },
      { id: 2, name: 'Febrero' },
      { id: 3, name: 'Marzo' },
      { id: 4, name: 'Abril' },
      { id: 5, name: 'Mayo' },
      { id: 6, name: 'Junio' },
      { id: 7, name: 'Julio' },
      { id: 8, name: 'Agosto' },
      { id: 9, name: 'Septiembre' },
      { id: 10, name: 'Octubre' },
      { id: 11, name: 'Noviembre' },
      { id: 12, name: 'Diciembre' },
    ])
    const productionLines = ref([])
    const productionLinesFilter = ref([])
    const years = ref([])

    onMounted(() => {
      const currentYear = new Date().getFullYear();

      for (let i = 2021; i <= currentYear; i++) {
        years.value.push(i);
      }

      APIBusiness.get()
        .then((response) => {
          businesses.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar empresas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })

      APIFloor.get()
        .then((response) => {
          floors.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar plantas";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })

      APIProductionLine.get()
        .then((response) => {
          productionLines.value = response.data.result;
        })
        .catch((error) => {
          alert.title = "Error al buscar líneas de producción";
          alert.message = error.response ? error.response.data.msg : 'Error en la petición';
          $('#toast').toast('show');
        })

      $('#bufferFilterModal').on('shown.bs.modal', function () {
        if (props.form.planta_id > 0) {
          productionLinesFilter.value = productionLines.value.filter(element => element.PlantaID == props.form.planta_id)
        }

        internalInstance.refs.filterForm.setValues({
          empresa_id: props.form.empresa_id,
          planta_id: props.form.planta_id,
          linea_produccion_id: props.form.linea_produccion_id,
        });
      })
    })

    function changeBusiness(value) {
      floorsFilter.value = floors.value.filter(element => element.EmpresaID == value);
      floorSelected.value = 0;
    }

    function changeFloor(value) {
      productionLinesFilter.value = productionLines.value.filter(element => element.PlantaID == value)
      productionLineSelected.value = 0;
    }

    function filter(values) {
      emit('filter', {
        values: values,
        business: businesses.value.find(element => element.ID == values.empresa_id),
        floor: floorsFilter.value.find(element => element.ID == values.planta_id),
        productionLine: productionLinesFilter.value.find(element => element.ID == values.linea_produccion_id),
      });

      $('#bufferFilterModal').modal('hide');
    }

    watchEffect(() => {
      formValues.año = props.form.año;
      formValues.mes = props.form.mes;
      formValues.empresa_id = props.form.empresa_id;
      formValues.planta_id = props.form.planta_id;
      formValues.linea_produccion_id = props.form.linea_produccion_id;
    })

    watch(businessSelected, (value) => {
      changeBusiness(value)
    })

    watch(floorSelected, (value) => {
      changeFloor(value)
    })

    watch(
      () => formValues.empresa_id,
      (businessId) => {
        changeBusiness(businessId)
      }
    )

    return {
      formSchema,
      formValues,
      businesses,
      floors,
      floorsFilter,
      months,
      productionLines,
      productionLinesFilter,
      years,
      filter,
      businessSelected,
      floorSelected,
      productionLineSelected,
    }
  }
}
</script>
