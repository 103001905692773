<template>
  <div class="row mb-2 mb-xl-3">
    <div class="col-auto d-block">
      <h4 class="text-dark">Buffer</h4>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-4" align="left">
      <div v-if="hasPermissionToDelete()">
        <input type="checkbox" name="checkboxDelete" v-model="checkedAll" @change="selectAll"> Seleccionar todos

        <button type="button" class="btn btn-danger" @click="showDelete">
          Eliminar
        </button>
      </div>
    </div>

    <div class="col-4 line-height" align="center">
      <span>{{ businessName }}</span>
      <span class="ml-3">Actualización: {{ updatedAt }}</span>
    </div>

    <div class="col-4" align="right">
      <button type="button" class="btn btn-success mr-2" @click="exportExcel">
        <i class="bi bi-file-earmark-excel"></i> Exportar Excel
      </button>

      <button type="button" class="btn btn-primary" @click="showFilters">
        <i class="bi bi-funnel"></i> Historial
      </button>
    </div>
  </div>

  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <a href="#" @click="filterBufferByBreadcrumb(1)">
          {{ filtersSelectedForBreadcrumb.business.Nombre }}
        </a>
      </li>
      <li class="breadcrumb-item" v-if="filtersSelectedForBreadcrumb.floor != undefined">
        <a href="#" @click="filterBufferByBreadcrumb(2)">
          {{ filtersSelectedForBreadcrumb.floor.Nombre }}
        </a>
      </li>
      <li class="breadcrumb-item" v-if="filtersSelectedForBreadcrumb.productionLine != undefined">
        <a href="#" @click="filterBufferByBreadcrumb(3)">
          {{ filtersSelectedForBreadcrumb.productionLine.Nombre }}
        </a>
      </li>
    </ol>
  </nav>

  <table id="bufferTable" class="table table-sm table-striped bg-white" style="width:100%">
    <thead class="thead-dark">
      <tr>
        <th v-if="hasPermissionToDelete()" class="checkbox"></th>
        <th class="font-weight-normal">{{ title }}</th>
        <th class="font-weight-normal text-center">Prog.Mes</th>
        <th class="font-weight-normal text-center">Hrs.Est.Mest</th>
        <th class="font-weight-normal text-center">Prog.Diario-Prod</th>
        <th class="font-weight-normal text-center">Prog.Acum</th>
        <th class="font-weight-normal text-center">CierreProd-Dia.Ant</th>
        <th class="font-weight-normal text-center">Acum.Prod-Mes.Actual</th>
        <th class="font-weight-normal text-center">Hrs.Est.Mes-Acum</th>
        <th class="font-weight-normal text-center">Dif.Hrs.Est-Mes.Acum</th>
        <th class="font-weight-normal text-center">Dif.Prog vs Prod.Mes.Actual</th>
        <th class="font-weight-normal text-center">Buffer</th>
      </tr>
    </thead>
    <tfoot>
        <tr>
            <th v-if="hasPermissionToDelete()"></th>
            <th class="font-weight-bold">Totales</th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
            <th class="font-weight-bold"></th>
        </tr>
    </tfoot>
  </table>

  <delete-confirmation
    :ids="itemsSelected"
    @deleted="loadList"
  ></delete-confirmation>

  <filters-modal
    :form="filtersSelected"
    @filter="filterBuffer"
  ></filters-modal>

  <toast :config="alert"></toast>
</template>

<script>
// @ is an alias to /src
import DeleteConfirmation from '@/components/Buffer/DeleteConfirmation';
import Filter from '@/components/Buffer/Filters';
import { onMounted, reactive, ref, nextTick } from 'vue';
import CONSTANS from '@/util/constants';
import actions from '@/util/actions';
import XLSX from 'xlsx';
import $ from 'jquery';

export default {
  components: {
    'delete-confirmation': DeleteConfirmation,
    'filters-modal': Filter,
  },

  setup() {
    const alert = reactive({
      message: "",
      title: "",
    })
    const filtersSelected = ref({})
    const defaultFiltersSelected = ref({
      año: new Date().getFullYear(),
      mes: new Date().getMonth()+1,
      empresa_id: parseInt(localStorage.empresa_id),
      planta_id: 0,
      linea_produccion_id: 0,

    })
    const checkedAll = ref(false)
    const title = ref('')
    const bufferIds = ref([])
    const bufferResult = ref([])
    const itemsSelected = ref([])
    const bufferTable = ref(null)
    const businessName = ref('')
    const updatedAt = ref('')
    const filtersSelectedForBreadcrumb = ref({
      business: { ID: localStorage.empresa_id, Nombre: localStorage.empresa_nombre },
      floor: undefined,
      productionLine: undefined,
    })

    onMounted(() => {
      initTable();
    })

    function hasPermissionToDelete() {
      return localStorage.tipo_usuario == 1;
    }

    function initTable() {
      nextTick(() => {
        var query = $.param(defaultFiltersSelected.value);

        var columns = [];

        if (hasPermissionToDelete()) {
          columns.push({
            data: null,
            render: function(data, type) {
              if (type === 'display') {
                var checked = itemsSelected.value.includes(data.Id) ? 'checked': '';
                return `<input type="checkbox" ${checked}>`;
              }

              return data;
            },
          });
        }

        columns.push({
          className: 'font-weight-light',
          data: 'Item',
          render: function(data, type) {
            if (type === 'display') {
              return title.value == 'Parte' ? data : `<a href="#">${data}</a>`;
            }

            return data;
          },
        });
        columns.push({ className: 'font-weight-light text-center', data: 'Programa' });
        columns.push({ className: 'font-weight-light text-center', data: 'HorasProgramadas' });
        columns.push({ className: 'font-weight-light text-center', data: 'ProgramaDiario' });
        columns.push({ className: 'font-weight-light text-center', data: 'ProgramaAcumulado' });
        columns.push({ className: 'font-weight-light text-center', data: 'ProduccionDiaAnterior' });
        columns.push({ className: 'font-weight-light text-center', data: 'ProduccionAcumulada' });
        columns.push({ className: 'font-weight-light text-center', data: 'HorasProduccionAcumulada' });
        columns.push({ className: 'font-weight-light text-center', data: 'DifHorasAcumuladas' });
        columns.push({ className: 'font-weight-light text-center', data: 'DifProgramaProduccion' });
        columns.push({ className: 'font-weight-light text-center', data: 'Buffer' });

        bufferTable.value = $('#bufferTable').DataTable({
          ajax:  {
            url: `${CONSTANS.API_URL}/buffer?${query}`,
            dataSrc: 'result',
            beforeSend: function (request) {
              request.setRequestHeader("Authorization", `Bearer ${localStorage.token}`);
            },
            complete: function (request) {
              title.value = request.responseJSON.item_titulo;
              var totals = request.responseJSON.totales;
              businessName.value = request.responseJSON.empresa_nombre;
              updatedAt.value = request.responseJSON.actualizacion;
              bufferResult.value = request.responseJSON.result.map(element => {
                var newElement = {};
                newElement[title.value] = element.Item;
                newElement['Prog.Mes'] = element.Programa;
                newElement['Hrs.Est.Mest'] = element.HorasProgramadas;
                newElement['Prog.Diario-Prod'] = element.ProgramaDiario;
                newElement['Prog.Acum'] = element.ProgramaAcumulado;
                newElement['CierreProd-Dia.Ant'] = element.ProduccionDiaAnterior;
                newElement['Acum.Prod-Mes.Actual'] = element.ProduccionAcumulada;
                newElement['Hrs.Est.Mes-Acum'] = element.HorasProduccionAcumulada;
                newElement['Dif.Hrs.Est-Mes.Acum'] = element.DifHorasAcumuladas;
                newElement['Dif.Prog vs Prod.Mes.Actual'] = element.DifProgramaProduccion;
                newElement['Buffer'] = element.Buffer;

                return newElement;
              });

              var columnIndex = hasPermissionToDelete() ? 2 : 1;

              $(bufferTable.value.column(columnIndex).footer()).html(totals.Programa);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.HorasProgramadas);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.ProgramaDiario);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.ProgramaAcumulado);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.ProduccionDiaAnterior);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.ProduccionAcumulada);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.HorasProduccionAcumulada);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.DifHorasAcumuladas);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.DifProgramaProduccion);
              $(bufferTable.value.column(++columnIndex).footer()).html(totals.Buffer);

              bufferTable.value.rows().invalidate().draw();
            },
            error: function(request) {
              if (request.status == 401) {
                actions.logout();
              }

              alert.title = "Error al buscar";
              alert.message = request.response ? request.response.data.msg : 'Error en la petición';
              $('#toast').toast('show');
            },
          },
          columns: columns,
          ordering: false,
          info: false,
          language: {
            emptyTable: 'Sin buffer',
            zeroRecords: "No se encontraron resultados",
            infoEmpty: "No se encontraron resultados",
            search: "Buscar:",
            lengthMenu: "Mostrar _MENU_ entradas",
            paginate: {
              next: "Siguiente",
              previous: "Previo",
            }
          },
        }).on( 'search.dt', function () {
          var data = bufferTable.value.rows({search:'applied'}).data();
          var ids = [];

          data.map(element => {
            ids.push(element.Id);
            return element.Id;
          });

          bufferIds.value = ids;

          itemsSelected.value = itemsSelected.value.filter(element => bufferIds.value.includes(element))
        });

        $('#bufferTable tbody').on('click', 'input[type=checkbox]', function () {
          var buffer = bufferTable.value.row($(this).parents('tr')).data();

          if (!$(this).is(':checked')) {
            itemsSelected.value = itemsSelected.value.filter(element => element != buffer.Id);
            return;
          }

          if (!itemsSelected.value.includes(buffer.Id)) {
            itemsSelected.value.push(buffer.Id);
          }
        });

        $('#bufferTable tbody').on('click', 'a', function () {
          var buffer = bufferTable.value.row($(this).parents('tr')).data();

          if (title.value == 'Planta') {
            filtersSelectedForBreadcrumb.value.floor = { Nombre: buffer.Item};
            filtersSelectedForBreadcrumb.value.productionLine = undefined;
            defaultFiltersSelected.value.planta_id = buffer.Id;
            defaultFiltersSelected.value.linea_produccion_id = 0;
          }

          if (title.value == 'Linea Producción') {
            filtersSelectedForBreadcrumb.value.productionLine = { Nombre: buffer.Item};
            defaultFiltersSelected.value.linea_produccion_id = buffer.Id;
          }

          loadList();
        });
      });
    }

    function loadList() {
      var query = $.param(defaultFiltersSelected.value);
      bufferTable.value.ajax.url(`${CONSTANS.API_URL}/buffer?${query}`).load();
    }

    function showDelete() {
      if (itemsSelected.value.length > 0) {
        $('#bufferDeleteModal').modal('show');
      }
    }

    function selectAll() {
      itemsSelected.value = checkedAll.value ? bufferIds.value : [];
      bufferTable.value.rows().invalidate().draw();
    }

    function showFilters() {
      filtersSelected.value = defaultFiltersSelected.value;
      $('#bufferFilterModal').modal('show');
    }

    function filterBufferByBreadcrumb(level) {
      if (level == 1) {
        filtersSelectedForBreadcrumb.value.floor = undefined;
        filtersSelectedForBreadcrumb.value.productionLine = undefined;
        defaultFiltersSelected.value.planta_id = 0;
        defaultFiltersSelected.value.linea_produccion_id = 0;
      }

      if (level == 2) {
        filtersSelectedForBreadcrumb.value.productionLine = undefined;
        defaultFiltersSelected.value.linea_produccion_id = 0;
      }

      loadList();
    }

    function filterBuffer(values) {
      filtersSelectedForBreadcrumb.value = {
        business: values.business,
        floor: values.floor,
        productionLine: values.productionLine,
      };

      defaultFiltersSelected.value = values.values;
      loadList();
    }

    function exportExcel() {
      let worksheet = XLSX.utils.json_to_sheet(bufferResult.value);

      const workbook = XLSX.utils.book_new();
      const filename = 'Buffer';

      XLSX.utils.book_append_sheet(workbook, worksheet, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    }

    return {
      filtersSelected,
      filtersSelectedForBreadcrumb,
      checkedAll,
      title,
      itemsSelected,
      loadList,
      showDelete,
      selectAll,
      showFilters,
      filterBuffer,
      filterBufferByBreadcrumb,
      exportExcel,
      businessName,
      updatedAt,
      hasPermissionToDelete,
    }
  }
}
</script>

<style lang="scss">
.line-height {
  line-height: 37.6px;
}

th {
  &.checkbox {
    width: 37px !important;
  }
}

#bufferTable_length,
#bufferTable_filter {
  .form-control {
    display: inline-block;
    width: auto;
  }
}

#bufferTable_filter {
  float: right;

  .form-control {
    margin-left: 5px;
  }
}
</style>
